<template>
  <div class="empty-page">
    <img src="../../assets/img/live_no_data.png" />
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: 'empty-data',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.empty-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 328px;
    height: 229px;
    margin-bottom: 16px;
    margin-top: 43px;
  }
  span {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    margin-bottom: 40px;
  }
}
</style>
