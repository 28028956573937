<template>
  <div class="course-page">
    <div v-if="!loading" class="content-wrap">
      <div class="type-wrap">
        <div class="type-item" :class="{'type-item-selected' : categoryId === ''}" @click="checkedCategory('')">全部</div>
        <div class="type-item" :class="{'type-item-selected' : categoryId === item.id}" v-for="(item, index) in categoryList" :key="index" @click="checkedCategory(item.id)">{{ item.name }}</div>
      </div>
      <div v-if="courseList.length > 0" class="course-wrap">
        <div class="course-list">
          <div class="course-item" v-for="(item, index) in courseList" :key="index" @click="toNavInfo(item)">
            <img v-if="item.pc_cover1" class="cover" :src="item.pc_cover1 ? item.pc_cover1 : item.front_cover4" />
            <div v-else class="cover">
              <default-cover />
            </div>
            <div class="info-wrap">
              <div class="title">{{ item.name }}</div>
              <div class="info">
                <span>{{ item.virtually_pay_num !== '0' && item.virtually_pay_num !== '' ? item.virtually_pay_num : item.user_count }}人已报名</span>
                <p v-if="parseFloat(item.price) > 0" class="price">￥{{ item.price }}</p>
                <p v-else>免费</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="total > 0" class="page-wrap">
          <el-pagination
              background
              layout="prev, pager, next, jumper"
              :page-size="queryParams.limit"
              :current-page="queryParams.page"
              :total="total"
              :title="pageTitle"
              @current-change="handleCurrentChange"
          />
          <div class="total-wrap">共 {{ total }} 条</div>
        </div>
      </div>
      <empty-data v-else />
    </div>
    <loading :is-load="loading" />
  </div>
</template>

<script>
import * as courseService from '@/service/course-service';
import emptyData from '@/views/modules/empty-data';
import { Message } from 'element-ui';
export default {
  name: 'courseSelection',
  components: {
    emptyData
  },
  data() {
    return {
      loading: false,
      categoryList: [], // 课程科目
      categoryId: '', // 当前科目id
      queryParams: {
        course_type: '1',
        limit: 16,
        page: 1
      },
      pageTitle: '',
      total: 0,
      courseList: [] // 课程列表
    };
  },
  mounted() {
    this.getCategoryList();
    this.getCategoryCourseData();
  },
  methods: {
    /**
     * 获取课程科目
     * */
    getCategoryList() {
      courseService.getCategoryList().then(res => {
        if (res.code === 1) {
          this.categoryList = res.data;
        }
      });
    },
    checkedCategory(id) {
      this.categoryId = id;
      this.queryParams.page = 1;
      this.total = 0;
      this.getCategoryCourseData();
    },
    /**
     * 获取分类课程列表
     * */
    getCategoryCourseData() {
      const data = {
        ...this.queryParams,
        category_id: this.categoryId
      };
      this.loading = true;
      courseService.getIndexCategory(data).then(res => {
        this.loading = false;
        if (res.code === 1) {
          this.courseList = res.data.list;
          this.total = res.data.total;
          const pages = Math.ceil(this.total / this.queryParams.limit);
          this.pageTitle = `值必须小于或等于${pages}`;
        }
      });
    },
    /**
     * 切换分页
     * */
    handleCurrentChange(val) {
      const inputVal = parseInt(document.querySelector('.el-pagination__jump .el-input__inner').value);
      const pages = Math.ceil(this.total / this.queryParams.limit);
      if (inputVal > pages) {
        Message({
          message: `值必须小于或等于${pages}`,
          type: 'warning',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
      }
      this.queryParams.page = val;
      this.getCategoryCourseData();
    },
    /**
     * 跳转详情
     * course_type 1=普通课程 2=直播课 0=套课
     * */
    toNavInfo(info) {
      const path = info.course_type === '0' ? '/packageDetail' : '/courseDetail';
      this.$router.push({
        path: path,
        query: {
          id: info.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.course-page {
  padding-bottom: 73px;
  .content-wrap {
    display: flex;
    flex-direction: column;
    .type-wrap {
      display: flex;
      flex-wrap: wrap;
      margin: 30px 0 16px 15px;
      line-height: 1;
      .type-item {
        padding-bottom: 9px;
        font-size: 18px;
        color: #666666;
        margin-right: 50px;
        cursor: pointer;
      }
      .type-item:hover {
        font-size: 18px;
        font-weight: bold;
        color: #2869F5;
      }
      .type-item-selected {
        font-size: 18px;
        font-weight: bold;
        color: #2869F5;
        position: relative;
      }
      .type-item-selected:after {
        position: absolute;
        content: '';
        left: calc( 50% - 10px );
        bottom: 0;
        width: 20px;
        height: 2px;
        background: #2869F5;
        border-radius: 1px;
      }
    }
    .course-list {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      .course-item {
        width: 286px;
        height: 250px;
        background: #FFFFFF;
        border-radius: 8px;
        cursor: pointer;
        overflow: hidden;
        line-height: 1;
        margin: 0 18px 20px 0;
        transition: all .4s;
        .cover {
          width: 286px;
          height: 176px;
        }
        .info-wrap {
          padding: 0 16px 0 15px;
          .title {
            margin: 14px 0 10px;
            font-size: 18px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
              font-size: 12px;
              font-weight: 400;
              color: #666666;
            }
            p {
              font-size: 18px;
              color: #E22323;
            }
            .price {
              font-weight: bold;
            }
          }
        }

      }
      .course-item:nth-child(4n) {
        margin-right: 0;
      }
      .course-item:hover {
        box-shadow: 2px 4px 9px 0px rgba(40,105,245,0.09);
        transform: translateY(-5px);
      }
    }
    .page-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 48px;
      ::v-deep .el-pagination.is-background .btn-next, ::v-deep .el-pagination.is-background .btn-prev, ::v-deep .el-pagination.is-background .el-pager li {
        background-color: #FFFFFF;
      }
      ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background: #2869F5;
      }
      ::v-deep .el-pagination__jump {
        line-height: 34px;
        height: 34px;
      }
      .total-wrap {
        margin-left: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>
