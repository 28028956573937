import { http, Problem } from '../utils/httpRequest';
/**
 * 获取首页数据
 * */
export function getIndexData() {
  return http({
    url: '/api/index/pcindex',
    method: 'GET'
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};
/**
 * 获取课程科目
 * */
export function getCategoryList() {
  return http({
    url: '/api/course/getCategoryList'
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};
/**
 * 根据分类查询课程
 * */
export function getIndexCategory(data) {
  return http({
    url: '/api/course/lists',
    method: 'POST',
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};

/**
 * 首页直播、模块更多列表查询
 * */
export function getCourseSearch(data) {
  return http({
    url: '/api/course/search',
    method: 'POST',
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};

/**
 * 课程详情
 * */
export function getCourseInfo(data) {
  return http({
    url: '/api/course/getInfo',
    method: 'POST',
    header: true,
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};
/**
 * 获取播放凭证
 * */
export function getvideoauth(data) {
  return http({
    url: '/api/course/getvideoauth',
    method: 'POST',
    header: true,
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};
/**
 * 获取教师信息
 * */
export function getCourseTeacherInfo(data) {
  return http({
    url: '/api/course/teacher',
    method: 'POST',
    header: true,
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};

/**
 * 获取评论列表
 * */
export function getCommentList(data) {
  return http({
    url: '/api/common/commentList',
    method: 'POST',
    header: true,
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};

/**
 * 点赞
 * */
export function commentLike(data) {
  return http({
    url: '/api/common/fa',
    method: 'POST',
    header: true,
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};

/**
 * 添加评论
 * */
export function publishComment(data) {
  return http({
    url: '/api/common/comment',
    method: 'POST',
    header: true,
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};

/**
* 我的课程
* */
export function getMyCourse(data) {
  return http({
    url: '/api/course/myCourse',
    method: 'POST',
    header: true,
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};

/**
 * 套课详情
 * */
export function getPackageInfo(data) {
  return http({
    url: '/api/package/getInfo',
    method: 'POST',
    header: true,
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};

/**
 * 记录视频观看时长
 * */
export function userLiveViewDuration(data) {
  return http({
    url: '/api/course/userLiveViewDuration',
    method: 'POST',
    data: JSON.stringify(data),
    header: true
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};

/**
 * 领取免费课程
 * */
export function getFreeCourse(data) {
  return http({
    url: '/api/order/freeGetCourse',
    method: 'POST',
    header: true,
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};

/**
 * 领取免费套课
 * */
export function getFreePackage(data) {
  return http({
    url: '/api/order/freeGetPackage',
    method: 'POST',
    header: true,
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};

/**
 * 我的套课
 * */
export function getMyPackage() {
  return http({
    url: '/api/package/myPackage',
    method: 'POST',
    header: true
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};

/**
 * 添加用户观看课程记录
 * */
export function addUserLiveLog(data) {
  return http({
    url: '/api/course/addUserLiveLog',
    method: 'POST',
    header: true,
    data: JSON.stringify(data)
  }).then(result => {
    return result.data;
  }).catch(error => {
    return Problem(error);
  });
};
